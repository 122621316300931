import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import List from 'components/LinkCard/List';
import ListItem from 'components/LinkCard/ListItem';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "better-support-for-chameleon-scripts",
      "style": {
        "position": "relative"
      }
    }}>{`Better support for chameleon-scripts`}</h3>
    <p>{`While moving projects to chameleon-scripts, we ran into some With this release
we're making chameleon-react fully compatible with chameleon-scripts and are
fixing some small issues in a few components. In addition to that we added some
new features in some components and moved things around under the hood.`}</p>
    <List my={8} mdxType="List">
  <ListItem title="v1.2.0 changelog" description="Get a full overview of what's changed in the changelog" to="/updates/changelog" mdxType="ListItem" />
    </List>
    <h3 {...{
      "id": "chameleon-versioning",
      "style": {
        "position": "relative"
      }
    }}>{`Chameleon versioning`}</h3>
    <p>{`Because we decided to change our versioning strategy, we're hard at work to
modify our packages so they're able to do so. With a few minor changes in the
running, we're almost at the finish. If you want to know what's changing, you
can take a look 👀 at the folowing link:
`}<a parentName="p" {...{
        "href": "https://confluence.tmg.nl/display/DNM/%5BDSY%5D+Theme+versioning+decision+tree"
      }}>{`https://confluence.tmg.nl/display/DNM/%5BDSY%5D+Theme+versioning+decision+tree`}</a></p>
    <h3 {...{
      "id": "looking-forward",
      "style": {
        "position": "relative"
      }
    }}>{`Looking forward`}</h3>
    <p>{`With the last steps runnig for our independent theme versioning, we're finishng
up, so stay tuned for more information over the next couple of weeks!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      